<template>
  <div>
    <div v-for="item in data" :key="item">{{ item }}</div>
  </div>
</template>

<script>

export default {
  props: {
    item: String,
  },
  data: () => ({
    data: null,
  }),
  created() {
    this.data = this.$adal.user.profile
  },
  name: "ProfileData",
};
</script>
